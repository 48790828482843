import React from 'react';
import { IntlProvider } from 'react-intl';
import de from '../../locales/de.json';
import fr from '../../locales/fr.json';
import it from '../../locales/it.json';

const messages: Record<string, Record<string, string>> = { de, fr, it };

const RootLayout = ({
  locale,
  children,
}: {
  locale: string;
  children: React.ReactNode;
}) => (
  <IntlProvider
    locale={locale}
    defaultLocale={'de'}
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
);

export default RootLayout;
